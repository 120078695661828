export const RECORD_BY_ID = (id) => `/attendance/student/${id}`;
export const RECORD = "/attendance/student/";
export const ACTIVITE_BY_ID = (id) => `/activities/${id}`;
export const ACTIVITE = `/activities`;
export const JOBS_BY_ID = (id) => `/opportunities/${id}`;
export const JOBS = "/opportunities";
export const EVENT_BY_ID = (id) => `/events/${id}`;
export const EVENT=`/events`;
export const PROCEDURE_BY_ID = (id) => `/procedures/${id}`;
export const PROCEDURE=`/procedures`;
export const LECTURERS=`/lecturers`;
export const LECTURERS_BY_ID=(id)=>`/lecturers/${id}`
export const Map_BY_ID=(slug,id)=>`/places/${slug}/${id}`
export const Map=(slug)=>`/places/${slug}`;
export const ARCHIVES=`/archives`;
export const ARCHIVES_BY_ID=(id)=>`/archives/${id}`;


export const NOTIFI=`/notifications`; //this is endpoint notifictions
export const NOTIFI_BY_ID=(id)=>`/notifications/${id}`; 

//home
export const BOOKING=`/`

export const INTERACTIVE_MUSEUM = `/internal-activities/interactive_museums`
export const INTERACTIVE_MUSEUM_BY_ID =(id)=> `/internal-activities/interactive_museums/${id}`

export const AWARENESS_SESSIONS = `/internal-activities/awareness_sessions`
export const AWARENESS_SESSIONS_BY_ID =(id)=> `/internal-activities/awareness_sessions/${id}`

export const TRAINING_HERITAGE = `/internal-activities/heritage_trainings`
export const TRAINING_HERITAGE_BY_ID =(id)=> `/internal-activities/heritage_trainings/${id}`