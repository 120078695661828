import * as ep from "../../endpoints";
import api from "@/libs/axios";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import Vue from "vue";
export default {
  namespaced: true,
  state: {
    loading: false,
  },
  getters: {
    loading(state) {
      return state.loading;
    },
  },
  mutations: {},
  actions: {
    async updateHalls(context, payload) {
      context.state.loading = true;
      await api.put(ep.HALLS_BY_ID(payload.id), payload.data).then((res) => {
        context.state.loading = false;
        Vue.$toast({
          component: ToastificationContent,
          props: {
            title: res.data.message,
            icon:  res.data.status ? "CheckIcon": "XIcon",
            variant: res.data.status ? "success" :"danger",
          },
        });
      });
    },
    async createHalls(context, payload) {
      context.state.loading = true;
      await api
        .post(ep.HALLS, payload.data)
        .then((res) => {
          context.state.loading = false;
          Vue.$toast({
            component: ToastificationContent,
            props: {
              title: res.data.message,
              icon:  res.data.status ? "CheckIcon": "XIcon",
              variant: res.data.status ? "success" :"danger",
            },
          });
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          context.state.loading = false;
        });
    },
    async deleteHalls(context, payload) {
      context.state.loading = true;
      await api.delete(ep.HALLS_BY_ID(payload.id)).then((res) => {
        context.state.loading = false;
        Vue.$toast({
          component: ToastificationContent,
          props: {
            title: res.data.message,
            icon:  res.data.status ? "CheckIcon": "XIcon",
            variant: res.data.status ? "success" :"danger",
          },
        });
      });
    },
  },
};
