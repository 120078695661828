export default [
  {
    path: "/public-pages",
    component: () => import("./moduleLayout.vue"),
    children: [
      {
        path: "evaluation",
        name:"evaluation",
        component: () => import("./pages/evaluation/index.vue"),
        meta: {
          layout: "full",
          // resource: "Auth",
          // redirectIfLoggedIn: true,
        },
      },
      {
        path: "enter-number",
        name:"enter-number",
        props:true,
        component: () => import("./pages/evaluation/EnterNumber.vue"),
        meta: {
          layout: "full",
          // resource: "Auth",
          // redirectIfLoggedIn: true,
        },
      },
      {
        path: "services",
        name:"services",
        props:true,
        component: () => import("./pages/services/index.vue"),
        meta: {
          layout: "full",
          // resource: "Auth",
          // redirectIfLoggedIn: true,
        },
      },
      {
        path: "tv1",
        name:"tv1",
        props:true,
        component: () => import("./pages/tv/index.vue"),
        meta: {
          layout: "full",
          // resource: "Auth",
          // redirectIfLoggedIn: true,
        },
      }

    ],
  },
];
