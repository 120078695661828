import Vue from "vue";
import VueRouter from "vue-router";
import AuthHelpers from "../utils/auth-helpers";

import dashboard from "../modules/manage-app/router";
import auth from "../modules/auth/router";
import requests from "../modules/manage-center/router"
import settings from "../modules/settings/router";
import publicPages from "../modules/public-pages/router";
Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes: [
    { path: "/", redirect: { name: "dashboard-home" } },
    ...auth,
    ...dashboard,
    ...requests,
    ...settings,
    ...publicPages,
    {
      path: "*",
      redirect: "error-404",
    },
  ],
});

router.beforeEach((to, _, next) => {
  if (to.meta.whiteListed) {
    console.log("whitelisted");
    return next();
  }
  const isLoggedIn = AuthHelpers.isUserLoggedIn();
  const isTokenExpiration = AuthHelpers.checkTokenExpiration();
  console.log("test test",isTokenExpiration)

  if (isTokenExpiration){
    return next({ name: "auth-login" });
  }
  else if (!isLoggedIn && !to.meta.whiteListed) {
    if (to.name === "auth-login") return next();
    else return next({ name: "auth-login" });
  }else return next();

});

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById("loading-bg");
  if (appLoading) {
    appLoading.style.display = "none";
  }
});

export default router;
