import * as ep from "../../endpoints";
import api from "@/libs/axios";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import Vue from "vue";
export default {
  namespaced: true,
  state: {
    loading: false,
    jobs: [],
    total_row:null,
  },
  getters: {
    loading(state) {
      return state.loading;
    },
    total_row(state){
      return state.total_row
    },
    jobs(state) {
      return state.jobs;
    },
  },
  mutations: {
    setJobs(state, payload) {
      state.jobs = payload;
    },
  },
  actions: {
    async getJobs(context,payload) {
      context.state.loading = true;
      await api.get(ep.JOBS,{params:payload}).then((res) => {
        context.state.loading = false;
        context.commit("setJobs",res.data.data)
        context.state.total_row=res.data.pagination.total_row
      }).catch((err) => {
        console.log(err);
        context.state.loading = false;
      })
      .finally(() => {
        context.state.loading = false;
      });
    },
    async updateJobs(context, payload) {
      context.state.loading = true;
      await api.put(ep.JOBS_BY_ID(payload.id), payload.fd).then((res) => {
        context.state.loading = false;
        Vue.$toast({
          component: ToastificationContent,
          props: {
            title: res.data.message,
            icon: res.data.status ? "CheckIcon": "XIcon",
            variant: res.data.status ? "success" :"danger",
          },
        });
      }).catch((err) => {
        console.log(err);
        context.state.loading = false;
      })
      .finally(() => {
        context.state.loading = false;
      });;
    },
    async createJobs(context, payload) {
      context.state.loading = true;
      await api
        .post(ep.JOBS, payload.fd)
        .then((res) => {
          context.state.loading = false;
          Vue.$toast({
            component: ToastificationContent,
            props: {
              title: res.data.message,
              icon: res.data.status ? "CheckIcon": "XIcon",
              variant: res.data.status ? "success" :"danger",
            },
          });
        })
        .catch((err) => {
          console.log(err);
          context.state.loading = false;
        })
        .finally(() => {
          context.state.loading = false;
        });
    },
    async deleteJobs(context, payload) {
      context.state.loading = true;
      await api.delete(ep.JOBS_BY_ID(payload.id)).then((res) => {
        context.dispatch("getJobs",{
          page: payload.page,
          per_page: payload.per_page,
          filter: payload.filter,
          type_id: payload.type_id,
        })
        context.state.loading = false;
        Vue.$toast({
          component: ToastificationContent,
          props: {
            title: res.data.message,
            icon: res.data.status ? "CheckIcon": "XIcon",
            variant: res.data.status ? "success" :"danger",
          },
        });
      });
    },
  },
};
