export default [
  {
    path: "/dashboard",
    component: () => import("./moduleLayout.vue"),
    children: [
      {
        path: "home",
        name:"dashboard-home",
        component: () => import("./pages/home/index.vue"),
      },
      {
        path: "events",
        name:"dashboard-events",
        component: () => import("./pages/events/index.vue"),
      },
      {
        path: "interactive-map",
        name:"interactive-map",
        component: () => import("./pages/interactive-map/index.vue"),
      },
      {
        path: "activite",
        name:"dashboard-activite",
        component: () => import("./pages/activite/index.vue"),
      },
      {
        path: "services",
        name:"dashboard-services",
        component: () => import("./pages/services/index.vue"),
      },
      {
        path: "opportunities",
        name:"dashboard-opportunities",
        component: () => import("./pages/jobs/index.vue"),
      },
      {
        path: "procedure",
        name:"dashboard-procedure",
        component: () => import("./pages/procedure/index.vue"),
      },
      {
        path: "archives",
        name:"archives",
        component: () => import("./pages/archives/index.vue"),
      },
      {
        path: "lecturers",
        name:"dashboard-lecturers",
        component: () => import("./pages/lecturers/index.vue"),
      },
      {
        path: "lecturers-info/:id",
        name:"lecturers-info",
        props:true,
        component: () => import("./pages/lecturers/info-lecturers.vue"),
      },
      {
        path: "notifictions",
        name: "notifictions",
        props:true,
        component: () => import("./pages/notifictions/index.vue"),
      },
      {
        path: "awareness-sessions",
        name: "awareness-sessions",
        component: () => import("./pages/activite/awareness-sessions/index.vue"),
        props: true,
      },
      {
        path: "interactive-museum",
        name: "interactive-museum",
        component: ()=> import("./pages/activite/interactive-museum/index.vue")
      },
      {
        path: "training-heritage",
        name: "training-heritage",
        component: ()=> import("./pages/activite/training-heritage/index.vue")
      },
    ],
  },
];
