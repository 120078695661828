export default [
  {
    path: "/setting",
    component: () => import("./moduleLayout.vue"),
    children: [
      {
        path: "setting-halls",
        name:"setting-halls",
        props:true,
        component: () => import("./pages/setting-halls/index.vue"),
      },
      {
        path: "setting-office",
        name:"setting-office",
        props:true,
        component: () => import("./pages/srtting-office/index.vue"),
      },
      {
        path: "manage-beneficiary",
        name:"manage-beneficiary",
        props:true,
        component: () => import("./pages/manage-beneficiary/index.vue"),
      },
      {
        path: "manage-user-center",
        name:"manage-user-center",
        props:true,
        component: () => import("./pages/manage-user-center/index.vue"),
      },
      {
        path: "manage-about-app",
        name:"manage-about-app",
        props:true,
        component: () => import("./pages/about-manara-app/indexApp.vue"),
      },
      {
        path: "manage-about-manara",
        name:"manage-about-manara",
        props:true,
        component: () => import("./pages/about-manara-app/indexManara.vue"),
      },
      {
        path: "archive-categories",
        name:"archive-categories",
        props:true,
        component: () => import("./pages/selects/pages/archive-categories/index.vue"),
      },
      {
        path: "opportunities-categories",
        name:"opportunities-categories",
        props:true,
        component: () => import("./pages/selects/pages/opportunities-categories/index.vue"),
      },
      {
        path: "tourist-tours-categories",
        name:"tourist-tours-categories",
        props:true,
        component: () => import("./pages/selects/pages/tourist-tours-categories/index.vue"),
      },
      {
        path: "activites-categories",
        name:"activites-categories",
        props:true,
        component: () => import("./pages/selects/pages/activites-categories/index.vue"),
      },
      {
        path: "type-tourist-facilities",
        name:"type-tourist-facilities",
        props:true,
        component: () => import("./pages/selects/pages/type-tourist-facilities/index.vue"),
      },
      {
        path: "manage-versions",
        name:"manage-versions",
        props:true,
        component: () => import("./pages/about-manara-app/versions.vue"),
      },
      {
        path: "manage-role",
        name:"manage-role",
        props:true,
        component: () => import("./pages/role/index.vue"),
      },
      {
        path: "add-role",
        name:"add-role",
        props:true,
        component: () => import("./pages/role/add-role.vue"),
      },
      {
        path:"update-role/:id",
        name:"update-role",
        props:true,
        component: () => import("./pages/role/add-role.vue"),
      },
      {
        path: "permissions-user/:id",
        name: "permissions-user",
        component: () => import("./pages/role/permissions-user.vue"),
        props: true,
      },

    ],
  },
];
