import * as ep from "../../endpoints";
import api from "@/libs/axios";

export default {
  namespaced: true,
  state: {
    loading: false,
    loadingAddToQueue:false,
    servicesQ: [],
    procedureQ: [],
  },
  getters: {
    loading(state) {
      return state.loading;
    },
    loadingAddToQueue(state){
      return state.loadingAddToQueue
    },
    servicesQ(state) {
      return state.servicesQ;
    },
    procedureQ(state) {
      return state.procedureQ;
    },
  },
  mutations: {},
  actions: {
    async getServices(context) {
      context.state.loading = true;
      await api.get(ep.SERVICES_QUEUE).then((res) => {
        context.state.loading = false;
        context.state.servicesQ = res.data.data;
      });
    },
    
    async getProcedure(context) {
      context.state.loading = true;
      await api.get(ep.PROCEDUR_QUEUE).then((res) => {
        context.state.loading = false;
        context.state.procedureQ = res.data.data;
      });
    },

    async addToQueue(context,payload) {
      context.state.loadingAddToQueue = true;
      await api.post(ep.QUEUE,payload.data).then((res) => {
        context.state.loadingAddToQueue = false;
      });
    },
  },
};
